import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import FeaturedArticles from '../../components/resources/FeaturedArticles';
import ArticleCard from '../../components/resources/ArticleCard';
import HrefLangManager from '../../components/HrefLangManager';
import cleanCrumbLabels from '../../utils/cleanCrumbLabels';
import { crumbSeparator } from '../../utils/variables';

const Resources = ({ pageContext, location }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	const {
		allMdx: { nodes }
	} = useStaticQuery(graphql`
		query ResourcesIndexQuery {
			allMdx(
				limit: 6
				filter: {
					frontmatter: { featured: { ne: true } }
					internal: { contentFilePath: { regex: "/resources-post/" } }
				}
			) {
				nodes {
					frontmatter {
						category
						slug
						articleCardTitle
						articleCardDescription
					}
				}
			}
		}
	`);

	return (
		<Layout>
			<Seo title="Resource Center | hear.com" />
			<HrefLangManager
				alternates={[
					['x-default', 'https://www.hear.com/resources/'],
					['en', 'https://www.hear.com/resources/'],
					['en-US', 'https://www.hear.com/resources/'],
					['en-CA', 'https://ca.hear.com/resources/']
				]}
			/>
			<div className="o-wrapper">
				<div className="o-row-2p5">
					<h1 className="c-resource-title">Resource Center</h1>
				</div>
				<div className="o-row-2p5">
					<Breadcrumb location={location} crumbSeparator={crumbSeparator} crumbs={cleanCrumbLabels(crumbs)} />
				</div>
				<div className="o-row-2">
					<p className="c-resource-copy">
						Hearing loss affects 48 million people in the United States, and that number continues to grow each day. But
						thanks to the latest innovations in hearing aid technology, over 10,000 people a day are treating their
						problem with new hearing aids. As one of the fastest-growing hearing care companies in the world, our goal
						is to educate and empower you with the knowledge you need to live your best life with the gift of better
						hearing. This resource center offers the most up-to-date information from the leading experts, hearing
						specialists and audiologists in the industry.
					</p>
				</div>
				<div className="o-row-4">
					<div className="c-resource-categories">
						<Link to="/resources/hearing-loss/" className="c-resource-categories__link c-resource-categories__link--1">
							<span className="c-resource-categories__name">Hearing Loss</span>
						</Link>
						<Link to="/resources/hearing-aids/" className="c-resource-categories__link c-resource-categories__link--2">
							<span className="c-resource-categories__name">Hearing Aids</span>
						</Link>
						<Link
							to="/resources/hearing-aid-technology/"
							className="c-resource-categories__link c-resource-categories__link--3"
						>
							<span className="c-resource-categories__name">Hearing Aid Technology</span>
						</Link>
					</div>
				</div>
				<div className="o-row-4">
					<div className="c-resource-divider"></div>
				</div>
				<div className="o-row-4">
					<h2 className="c-resource-sub-title">Featured Articles</h2>
				</div>
				<FeaturedArticles />
				<div className="o-row-2 o-row-3-p">
					<div className="c-resource-cards-wrapper">
						{nodes.map((post, i) => (
							<ArticleCard
								key={i}
								url={`/resources/${post.frontmatter.category}/${post.frontmatter.slug}`}
								title={post.frontmatter.articleCardTitle}
								description={post.frontmatter.articleCardDescription}
							/>
						))}
					</div>
				</div>
				<div className="o-row-4">
					<Link
						to="/resources/all-articles"
						className="c-resource__cta"
						style={{ margin: '0 auto', display: 'block', width: '160px', textAlign: 'center' }}
					>
						All Articles
					</Link>
				</div>
			</div>
		</Layout>
	);
};

export default Resources;
